import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import {
    ArrowLeftIcon,
    ArrowPathIcon,
    ArrowRightIcon,
    Bars3Icon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    GlobeAsiaAustraliaIcon,
    LockClosedIcon,
    MapPinIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronRightIcon, PhoneXMarkIcon } from '@heroicons/react/20/solid'
import { Avatar, Divider, HStack, Icon, Skeleton, SkeletonCircle, Spinner, Stack, VStack, Wrap } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import logo from '../img/logo.png';
import baristaBanner from '../img/cbd-college-banners/barista.gif';
import firstAidBanner from '../img/cbd-college-banners/first-aid.jpg';
import rcgBanner from '../img/cbd-college-banners/rcg.jpg';
import rsaBaristaBanner from '../img/cbd-college-banners/rsa-barista.jpg';
import rsaRcgBaristaBanner from '../img/cbd-college-banners/rsa-rcg-barista.jpg';
import rsaRcgBanner from '../img/cbd-college-banners/rsa-rcg.jpg';
import rsaBanner from '../img/cbd-college-banners/rsa.jpg';
import whiteCardBanner from '../img/cbd-college-banners/white-card.jpg';
import JobWidget from '../components/JobWidget';
import { LuConstruction } from 'react-icons/lu';
import { TbChefHat } from 'react-icons/tb';
import { IoMdWine } from 'react-icons/io';
import { BiCoffee } from 'react-icons/bi';

export default function Home() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [moreJobs, setMoreJobs] = useState(true);
    const [start, setStart] = useState(0);
    const [jobCategories, setJobCategories] = useState([]);
    const [loadingJobs, setLoadingJobs] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState('sydney');


    useEffect(() => {
        getJobs();
    }, []);


    const location = useLocation();

    async function updateCategory(category) {
        setJobs([]);
        setStart(0);
        setSelectedCategory(category);
        getJobs(category, selectedLocation, 0);
    }


    async function updateLocation(location) {
        setJobs([]);
        setStart(0);
        setSelectedLocation(location);
        getJobs(selectedCategory, location, 0);
    }


    async function getJobs(category = null, location = null, forceStart = null) {
        console.log('getJobs');
        setLoadingJobs(true);
        console.log('start')
        console.log(start);

        var currStart = start;
        if (forceStart != null) {
            currStart = forceStart;
        }
        if (currStart == 0) {
            setMoreJobs(true);
        }
        const currCategory = category ? category : selectedCategory;
        const currLocation = location ? location : selectedLocation;
        console.log(currCategory);
        console.log(currLocation);

        ReactGA.event({
            category: 'Get Jobs',
            action: 'get_jobs',
            label: JSON.stringify({
                'category': currCategory,
                'location': currLocation,
            }),
        });


        var queryStringParameters = {
            kind: 'list',
            offset: currStart,
        }

        if (currCategory) {
            queryStringParameters.category = currCategory;
        }

        if (currLocation) {
            queryStringParameters.location = currLocation;
        }

        console.log('query string parameters');
        console.log(queryStringParameters);

        const out = await API.get('cbdcollegerest', '/jobs', {
            response: true,
            queryStringParameters: queryStringParameters,
        });
        const newJobs = out.data.jobs;
        console.log('new jobs');
        console.log(newJobs);
        if (newJobs.length === 0) {
            console.log('setting jobs false');
            setMoreJobs(false);
        } else {
            if (currStart == 0) {
                setJobs(newJobs);
            } else {
                setJobs([...jobs, ...newJobs]);
            }
            setStart(currStart + 15);
            console.log(currStart);
        }
        setLoadingJobs(false);
    }



    const navigation = {
        internships1: [
            { name: 'Every SWE Internship', href: 'https://internstreet.co/swe' },
            { name: 'Every AI Internship', href: 'https://internstreet.co/ai' },
            { name: 'Every ML Internship', href: 'https://internstreet.co/ml' },
            { name: 'Every Data Science Internship', href: 'https://internstreet.co/datasci' },
        ],
        internships2: [
            { name: 'Every IB Internship', href: 'https://internstreet.co/ib' },
            { name: 'Every Product Manager Internship', href: 'https://internstreet.co/pm' },
            { name: 'Every Healthcare Internship', href: 'https://internstreet.co/healthcare' },
            { name: 'Every Finance Internship', href: 'https://internstreet.co/finance' },
        ],
        websites: [
            {
                name: 'CBD College',
                href: 'https://www.cbdcollege.edu.au/',
            },
            {
                name: 'CBD College Sydney RSA',
                href: 'www.cbdcollegesydneyrsa.edu.au',
            },
            {
                name: 'CBD College White Card NSW',
                href: 'www.cbdcollegewhitecardnsw.edu.au',
            },
            {
                name: 'Barista Basics',
                href: 'www.baristabasics.com.au',
            },
            {
                name: 'Australian Barista School',
                href: 'www.australiabaristaschool.com.au',
            },
        ],
        social: [],
    }

    const jobCategoryDict = {
        'barista': {
            'title': 'Barista',
            'color': 'gray-500',
            'icon': BiCoffee,
        },
        'bartending': {
            'title': 'Bar',
            'color': 'gray-500',
            'icon': IoMdWine,
        },
        'waiter': {
            'title': 'Restaurant',
            'color': 'gray-500',
            'icon': TbChefHat,
        },
        'labour': {
            'title': 'Construction',
            'color': 'gray-500',
            'icon': LuConstruction,
        },
    }


    const jobLocationDict = {
        'sydney': {
            'title': 'Sydney',
        },
        'melbourne': {
            'title': 'Melbourne',
        },
        'adelaide': {
            'title': 'Adelaide',
        },
        'brisbane': {
            'title': 'Brisbane',
        },
        'newcastle': {
            'title': 'Newcastle',
        },
    }


    const bannerDictionary = {
        'barista': [
            {
                'src': baristaBanner,
                'href': 'www.baristabasics.com.au/',
            },
            {
                'src': rsaBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-Accredited-Barista-Package;Info;1994/',
            },
            {
                'src': rsaRcgBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Accredited-Barista-Package;Info;1995',
            },
        ],
        'bartending': [
            {
                'src': rsaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA;Info;1987',
            },
            {
                'src': rsaBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-Accredited-Barista-Package;Info;1994/',

            },
            {
                'src': rsaRcgBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Accredited-Barista-Package;Info;1995',
            },
            {
                'src': rcgBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RCG;Info;1988',
            },
            {
                'src': rsaRcgBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Package;Info;1993',
            },
        ],
        'waiter': [
            {
                'src': rsaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA;Info;1987',
            },
            {
                'src': rsaBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-Accredited-Barista-Package;Info;1994/',
            },
            {
                'src': rsaRcgBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Accredited-Barista-Package;Info;1995',
            }
        ],
        'labour': [
            {
                'src': whiteCardBanner,
                'href': 'https://www.cbdcollege.edu.au/?White-Card-(One-Day-Workshop);Info;2207',
            },
            {
                'src': rsaRcgBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Package;Info;1993',
            },
            {
                'src': rsaRcgBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Accredited-Barista-Package;Info;1995',
            },
        ],
        'all': [
            {
                'src': baristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-Accredited-Barista-Package;Info;1994/',
            },
            {
                'src': firstAidBanner,
                'href': 'https://www.cbdcollege.edu.au/?First-Aid-Courses;Info;2206',
            },
            {
                'src': rcgBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RCG;Info;1988',
            },
            {
                'src': rsaBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-Accredited-Barista-Package;Info;1994/',
            },
            {
                'src': rsaRcgBaristaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Accredited-Barista-Package;Info;1995',
            },
            {
                'src': rsaRcgBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA-RCG-Package;Info;1993',
            },
            {
                'src': rsaBanner,
                'href': 'https://www.cbdcollegesydneyrsa.edu.au/61405913-429g/?RSA;Info;1987',
            },
            {
                'src': whiteCardBanner,
                'href': 'https://www.cbdcollege.edu.au/?White-Card-(One-Day-Workshop);Info;2207',
            },
        ],
    };


    function GenerateBanner() {
        var category = 'all';
        if (selectedCategory != null) {
            category = selectedCategory;
        }
        const img1 = bannerDictionary[category][Math.floor(Math.random() * bannerDictionary[category].length)];
        var img2 = bannerDictionary[category][Math.floor(Math.random() * bannerDictionary[category].length)];
        while (img2 == img1) {
            img2 = bannerDictionary[category][Math.floor(Math.random() * bannerDictionary[category].length)];
        }
        var img3 = bannerDictionary[category][Math.floor(Math.random() * bannerDictionary[category].length)];
        while (img3 == img1 || img3 == img2) {
            img3 = bannerDictionary[category][Math.floor(Math.random() * bannerDictionary[category].length)];
        }

        return (
            <VStack
                width='100%'
            >
                <VStack
                    alignItems='start'
                    overflowX='hidden'
                    py={4}
                    px={4}
                    backgroundColor='blue.50'
                    borderRadius='12px'
                    m={4}
                    mt={7}
                    _hover={{
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                        transition: 'box-shadow 0.2s'
                    }}
                >
                    <p className='text-gray-400 font-semibold text-xl animate-pulse'>
                        <span className='text-sky-600 font-bold'>
                            Exclusive Offers
                        </span>
                        {' • '}
                        CBD College JobFinder Offers
                    </p>
                    <HStack
                        spacing={6}
                    >
                        <a href={img1.href} target='_blank'>
                            <img
                                src={img1.src}
                                className='h-52'
                            />
                        </a>
                        <a href={img2.href} target='_blank' className='hidden md:flex'>
                            <img
                                src={img2.src}
                                className='h-52'
                            />
                        </a>
                        <a href={img3.href} target='_blank' className='hidden lg:flex'>
                            <img
                                src={img3.src}
                                className='h-52'
                            />
                        </a>
                    </HStack>
                </VStack >
            </VStack>
        );
    }


    return (
        <div className="bg-white">
            <main>
                {/* Header */}
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">CBD College JobFinder</span>
                                <HStack>
                                    <img
                                        className="h-16 w-auto"
                                        src={logo}
                                        alt=""
                                    />
                                </HStack>
                            </a>
                        </div>
                    </nav>
                </header >

                <main className="isolate">
                    {/* Hero section */}
                    <div className="relative pt-14">
                        <div
                            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#1E3B70] to-[#29539B] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                        <div className="py-14 sm:py-16">
                            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                <div className="mx-auto max-w-3xl text-center">
                                    <VStack
                                        alignItems='center'
                                        width='100%'
                                    >
                                        <h1 className="hidden sm:flex text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl md:text-6xl">
                                            <HStack>
                                                <img
                                                    className="h-24 w-auto"
                                                    src={logo}
                                                    alt=""
                                                />
                                            </HStack>
                                        </h1>
                                        <h2 className='text-2xl font-semibold tracking-tight text-gray-600 mt-6'>
                                            CBD College is here to help you find your next job in Australia.
                                        </h2>
                                    </VStack>
                                    <div className="mt-3 sm:mt-10 flex items-center justify-center gap-x-6">
                                        <VStack
                                            alignItems='start'
                                            width='100%'
                                            spacing={2}
                                        >
                                            <p className="text-sm text-gray-500 font-bold">
                                                JOB TITLE
                                            </p>
                                            <Wrap
                                                width='100%'
                                            >
                                                <button
                                                    type="button"
                                                    className={
                                                        selectedCategory == null ?
                                                            "rounded-md bg-sky-100 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm"
                                                            :
                                                            "rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    }
                                                    onClick={() => updateCategory(null)}
                                                >
                                                    All Jobs
                                                </button>
                                                {Object.keys(jobCategoryDict).map((category) => (
                                                    <button
                                                        type="button"
                                                        className={
                                                            selectedCategory == category ?
                                                                "rounded-md bg-sky-100 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm"
                                                                :
                                                                "rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                        }
                                                        onClick={() => updateCategory(category)}
                                                    >
                                                        <HStack>
                                                            <Icon as={jobCategoryDict[category].icon} className="h-4 w-4 text-gray-500" aria-hidden="true" />
                                                            <p>
                                                                {jobCategoryDict[category].title} Jobs
                                                            </p>
                                                        </HStack>
                                                    </button>
                                                ))}
                                            </Wrap>
                                        </VStack>
                                    </div>
                                    <div className="mt-3 sm:mt-10 flex items-center justify-center gap-x-6">
                                        <VStack
                                            alignItems='start'
                                            width='100%'
                                            spacing={2}
                                        >
                                            <p className="text-sm text-gray-500 font-bold">
                                                LOCATION
                                            </p>
                                            <Wrap
                                                width='100%'
                                            >
                                                {Object.keys(jobLocationDict).map((location) => (
                                                    <button
                                                        type="button"
                                                        className={
                                                            selectedLocation == location ?
                                                                "rounded-md bg-sky-100 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm"
                                                                :
                                                                "rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                        }
                                                        onClick={() => updateLocation(location)}
                                                    >
                                                        {jobLocationDict[location].title}
                                                    </button>
                                                ))}
                                            </Wrap>
                                        </VStack>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#1E3B70] to-[#29539B] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                    </div>

                    {/* Table section */}
                    <div className="mx-auto mt-0 max-w-7xl px-0 sm:px-6 sm:mt-8 lg:px-8 mb-32">
                        <InfiniteScroll
                            dataLength={jobs.length}
                            next={getJobs}
                            hasMore={moreJobs}
                            loader={<div className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 sm:rounded-xl overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5">
                                <VStack spacing={2}>
                                    <SkeletonCircle size="12" />
                                </VStack>
                                <VStack
                                    spacing={2}
                                    width='100%'
                                    alignItems='start'
                                >
                                    <Skeleton height="24px" width='100%' />
                                    <Skeleton height="24px" width='40%' />
                                </VStack>
                                <div className="flex shrink-0 items-center gap-x-4">
                                    <div className="hidden sm:flex sm:flex-row justify-center sm:items-end">
                                        <HStack spacing={6}>
                                            <div className='flex mr-8'>
                                                <Skeleton size="md" />
                                            </div>
                                            <Skeleton size="md" />
                                            <Skeleton size="md" />
                                        </HStack>
                                    </div>
                                </div>
                            </div>}
                            endMessage={
                                <div>
                                    {
                                        loadingJobs &&
                                        <div className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 sm:rounded-xl overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5">
                                            <VStack spacing={2}>
                                                <SkeletonCircle size="12" />
                                            </VStack>
                                            <VStack
                                                spacing={2}
                                                width='100%'
                                                alignItems='start'
                                            >
                                                <Skeleton height="24px" width='100%' />
                                                <Skeleton height="24px" width='40%' />
                                            </VStack>
                                            <div className="flex shrink-0 items-center gap-x-4">
                                                <div className="hidden sm:flex sm:flex-row justify-center sm:items-end">
                                                    <HStack spacing={6}>
                                                        <div className='flex mr-8'>
                                                            <Skeleton size="md" />
                                                        </div>
                                                        <Skeleton size="md" />
                                                        <Skeleton size="md" />
                                                    </HStack>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !loadingJobs &&
                                        <HStack
                                            alignItems='center'
                                            justifyContent='center'
                                            width='100%'
                                            p='6'
                                        >
                                            <p className='text-gray-400'>
                                                That's it for today. Come back tomorrow for more!
                                            </p>
                                        </HStack>
                                    }
                                </div>
                            }
                        >
                            {jobs.map((job, index) => (
                                <Fragment key={job.job_id}>
                                    <JobWidget job={job} />
                                    {(index + 1) % 10 === 0 &&
                                        <GenerateBanner />
                                    }
                                </Fragment>
                            ))}
                        </InfiniteScroll>
                        {moreJobs &&
                            <VStack
                                alignItems='center'
                            >
                                <button
                                    type="button"
                                    className="relative flex justify-between gap-x-6 px-2 py-2 hover:bg-gray-50 sm:px-6 sm:rounded-sm overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 mt-12"
                                    onClick={() => getJobs(null, null, start)}
                                >
                                    Load More Jobs
                                </button>
                            </VStack>
                        }
                    </div>
                </main >
            </main >
            <footer className="bg-white" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <VStack
                            alignItems='start'
                            justifyContent='space-between'
                        >
                            <div className="space-y-2">
                                <img
                                    className="h-12"
                                    src={logo}
                                    alt=""
                                />
                                <p className="text-sm leading-6 text-gray-600">
                                    CBD College JobFinder is a free service provided by CBD College.
                                </p>
                                <p className="text-sm leading-6 text-gray-600">
                                    Contact: 1300 628 299
                                </p>
                                <div className="flex space-x-6">
                                    {navigation.social.map((item) => (
                                        <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">{item.name}</span>
                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </VStack>
                    </div>
                    <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-12">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                    CBD College Courses
                                </h3>
                                <ul role="list" className="mt-2 space-y-2">
                                    {navigation.websites.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
                        <HStack
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className="text-xs leading-5 text-gray-500">&copy; 2023 CBD College Pty Ltd. All rights reserved.</p>
                            <VStack
                                alignItems='start'
                            >
                                <p className='text-xs'>
                                    Built with
                                    <a href='https://webtranspose.com' className='ml-1 hover:underline text-indigo-600'>
                                        Web Transpose
                                    </a>
                                </p>

                                <a href='https://webtranspose.com'>
                                    <img
                                        src='/built-with-black.png'
                                        className='h-8'
                                    />
                                </a>
                            </VStack>
                        </HStack>
                    </div>
                </div>
            </footer >
        </div >
    )
}
