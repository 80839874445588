import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import * as React from 'react'
import { Fragment } from 'react'
import { ChakraProvider, useTheme } from '@chakra-ui/react'
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import ReactGA from 'react-ga4';
import config from './aws-exports';
Amplify.configure(config);


function App() {

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-4P3VTJF56J",
      },
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <ChakraProvider>
      <ThemeProvider className='bg-white'>
        <BrowserRouter className='bg-white'>
          <Routes>
            <Route exact path='/' element={<Home />} />
          </Routes >
        </BrowserRouter>
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
