
import React from 'react';
import { ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { HStack, Icon, VStack } from '@chakra-ui/react';
import { LuConstruction } from 'react-icons/lu';
import { TbChefHat } from 'react-icons/tb';
import { IoMdWine } from 'react-icons/io';
import { BiCoffee } from 'react-icons/bi';
import ReactGA from 'react-ga4';

const jobCategoryDict = {
    'barista': {
        'title': 'Barista',
        'color': 'gray-500',
        'icon': BiCoffee,
    },
    'bartending': {
        'title': 'Bar',
        'color': 'gray-500',
        'icon': IoMdWine,
    },
    'waiter': {
        'title': 'Restaurant',
        'color': 'gray-500',
        'icon': TbChefHat,
    },
    'labour': {
        'title': 'Construction',
        'color': 'gray-500',
        'icon': LuConstruction,
    },
}

const jobLocationDict = {
    'sydney': {
        'title': 'Sydney',
    },
    'melbourne': {
        'title': 'Melbourne',
    },
}


async function openJob(url) {
    ReactGA.event({
        category: 'Open Job',
        action: 'open_job',
        label: url,
    });
    window.open(url, '_blank', 'noreferrer');
}


export default function JobWidget({ job }) {
    return (
        <VStack
            alignItems='start'
            width='100%'
            onClick={() => openJob(job.job_url)}
            cursor='pointer'
        >
            <VStack
                alignItems='start'
                width='100%'
                minWidth='100%'
                spacing={0}
                cursor='pointer'
                mt={4}
                px={2}
            >
                <div className="relative flex justify-between gap-x-2 px-4 py-5 hover:bg-sky-50 sm:px-6 sm:rounded-xl overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 w-full" >
                    <div className="flex min-w-0 gap-x-4">
                        {/*
            <a href={job.company_url} className='h-12 w-12 min-w-max'>
                <img
                    className="h-12 w-12 flex-none rounded-full bg-gray-50 object-contain"
                    src={'https://logo.clearbit.com/' + job.company_url}
                    onError={(e) => e.target.src = logoCircle}
                />
            </a>
*/}
                        <div className="min-w-0 flex-auto">
                            <p className="text-md sm:text-lg font-semibold leading-6 text-gray-900">
                                <span className="absolute inset-x-0 -top-px bottom-0" />
                                {job.job_name}
                            </p>
                            <p className="mt-0 sm:mt-1 flex text-sm sm:text-md leading-5 text-gray-500">
                                {job.category ? job.category + ' •' : ''} {job.company_name}
                            </p>
                            <HStack spacing={1} mt={1}>
                                <MapPinIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
                                <p className="flex text-xs sm:text-sm leading-5 text-gray-500">
                                    {job.job_location}
                                </p>
                            </HStack>
                        </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-4">
                        <div className="hidden sm:flex sm:flex-row justify-center sm:items-end">
                            <HStack spacing={6}>
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default"
                                >
                                    <HStack>
                                        <Icon as={jobCategoryDict[job.category_filter].icon} className="h-4 w-4 text-gray-500" aria-hidden="true" />
                                        <p>
                                            {jobCategoryDict[job.category_filter].title}
                                        </p>
                                    </HStack>
                                </button>
                                <button
                                    type="button"
                                    className="rounded-md bg-sky-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                    <HStack justifyContent='space-between'>
                                        <p>
                                            Apply
                                        </p>
                                        <Icon as={ChevronRightIcon} />
                                    </HStack>
                                </button>
                            </HStack>
                        </div>
                    </div>
                </div>
            </VStack>
        </VStack>
    );
}