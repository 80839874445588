/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "cbdcollegerest",
            "endpoint": "https://ucahxiowfg.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
